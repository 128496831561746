<template>
  <b-row>
    <b-col class="name">
      <b-form-group>
        Reset Peer
      </b-form-group>
    </b-col>
    <b-col class="stream">
      <b-form-group>
        <b-form-radio
          v-model="value.stream"
          value="upstream"
        >
          upstream
        </b-form-radio>
        <b-form-radio
          v-model="value.stream"
          value="downstream"
        >
          downstream
        </b-form-radio>
      </b-form-group>
    </b-col>
    <b-col class="toxicity">
      <b-form-group>
        <b-form-slider
          v-model="value.toxicity"
          :min="0"
          :max="100"
          :step="5"
        />
      </b-form-group>
      <div>
        {{ (value.toxicity / 100).toFixed(2) }}
      </div>
    </b-col>
    <b-col class="attributes">
      <b-form-group
        label="Timeout"
        class="timeout"
      >
        <b-form-slider
          v-model="value.attributes.timeout"
          :min="0"
          :max="5000"
          :step="100"
        />
        <div>
          {{ value.attributes.timeout }} ms
        </div>
      </b-form-group>
    </b-col>
    <b-col class="buttons">
      <div>
        <b-button
          :class="{ 'enabled': isDirty }"
          variant="success"
          :disabled="!isDirty"
          @click="updateRule"
        >
          Save
        </b-button>
        <b-spinner
          v-if="saving"
          variant="danger"
          style="width: 1.75rem; height: 1.75rem;"
        />
      </div>
      <div>
        <b-button
          @click="deleteRule"
          variant="danger"
        >
          Delete
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import CommonMixins from '../mixins-common'

export default {
  name: 'toxic-rule-timeout',
  mixins: [
    CommonMixins
  ],
  props: {
    serviceName: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      dirty: false,
      saving: false,
      value: {
        toxicity: this.item.toxicity * 100,
        attributes: {
          timeout: this.item.attributes.timeout
        },
        stream: this.item.stream
      }
    }
  },
  watch: {
    value: {
      handler: function() {
        this.dirty = true
      },
      deep: true
    }
  }
}
</script>

<style scoped>
  .buttons .button {
    margin: 0 1.2rem;
  }
</style>
