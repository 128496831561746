<template>
  <b-container class="proxy-service-rules">
    <section class="service-rules">
      <b-row class="header">
        <b-col class="name">
          Name
        </b-col>
        <b-col class="stream">
          Stream
        </b-col>
        <b-col class="toxicity">
          Toxicity
        </b-col>
        <b-col class="attributes">
          Attributes
        </b-col>
        <b-col class="operations" />
      </b-row>
      <template
        v-for="(rule, idx) in serviceRules"
      >
        <component
          :is="toxicRule(rule)"
          :key="idx"
          :class="{ 'odd': idx % 2 === 1, 'even' : idx % 2 === 0}"
          :item="rule"
          :service-name="serviceName"
          @save="(data) => updateRule(data, idx)"
          @delete="deleteRule(idx)"
        />
      </template>
    </section>
    <section class="service-operations">
      <b-row class="buttons">
        <b-col>
          <b-form-group
            class="available-rules"
            label="Available Rulesets"
          >
            <div>
              <b-form-select
                v-model="selectedRuleSet"
                :options="availableRulesets"
              />
            </div>
            <b-button
              :class="{ dirty: 'enabled' }"
              :disabled="!newRulesSelected"
              variant="primary"
              @click="loadServiceRuleset"
            >
              Load
            </b-button>
            <b-icon
              v-if="savingServiceRuleset"
              icon="arrow-counterclockwise"
              animation="spin-reverse"
              font-scale="4"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="available-types"
            label="Available Ruletypes"
          >
            <div>
              <b-form-select
                v-model="selectedRuletype"
                :options="availableRuleTypes"
              />
            </div>
            <b-button
              :class="{ dirty: 'enabled' }"
              :disabled="!addRuleSelected"
              variant="success"
              @click="addRule"
            >
              New Rule
            </b-button>
          </b-form-group>
          <b-icon
            v-if="addingServiceRule"
            style="width: 1.75rem; height: 1.75rem;"
          />
        </b-col>
        <b-col class="clear-items">
          <div>
            <b-button
              :disabled="!hasServiceRules"
              variant="danger"
              @click="clearRules"
            >
              Clear Rules
            </b-button>
            <b-spinner
              v-if="clearing"
              variant="danger"
              style="width: 1.75rem; height: 1.75rem;"
            />
          </div>
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>

<script>
import ToxicRuleLatency from './ToxicRules/latency'
import ToxicRuleTimeout from './ToxicRules/timeout'
import ToxicRuleResetPeer from './ToxicRules/connection_reset'

import CommonMixins from './mixins-common'

export default {
  name: 'service-rules-table',
  mixins: [
    CommonMixins
  ],
  props: {
    serviceName: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      dirty: false,
      clearing: false,
      saving: false,
      addingServiceRule: false,
      savingServiceRuleset: false,
      selectedRuleSet: '--',
      selectedRuletype: '--'
    }
  },
  computed: {
    hasServiceRules: function() {
      return this.serviceRules.length > 0
    },
    serviceRules: function() {
      return this.$store.getters.ServiceRules(this.serviceName)
    },
    availableRulesets: function() {
      return Object.entries(this.$store.getters.availableServiceRulesNames(this.serviceName))
        .reduce((acc, [key, data]) => ([...acc, { value: key, text: data.name }]), [{ value: '--', text: '-- current --' }])
    },
    newRulesSelected: function() {
      return this.selectedRuleSet !== '--'
    },
    addRuleSelected: function() {
      return this.selectedRuletype !== '--'
    },
    availableRuleTypes: function() {
      return [
        { value: '--', text: '--' },
        { value: 'latency', text: 'Latency' },
        { value: 'reset_peer', text: 'Rest Peer' },
        { value: 'timeout', text: 'Timeout' }
      ]
    }
  },
  watch: {

  },
  methods: {
    addRule: function() {
      const defaultAttributres = {
        timeout: {
          timeout: 150
        },
        reset_peer: {
          timeout: 150
        },
        latency: {
          jitter: 1500,
          latency: 1500
        }
      }
      this.$store.commit('ADD_NEW_RULE', {
        serviceName: this.serviceName,
        rule: {
          type: this.selectedRuletype,
          stream: 'upstream',
          toxicity: 1,
          attributes: defaultAttributres[this.selectedRuletype]
        }
      })
    },
    clearRules: function() {
      this.clearing = true
      this.$store.dispatch('clearServiceRules', { serviceName: this.serviceName })
        .then(_ => {
          this.delay(() => {
            this.clearing = false
          })
        })
    },
    toxicRule: function(rule) {
      switch (rule.type) {
      case 'latency': return ToxicRuleLatency
      case 'timeout': return ToxicRuleTimeout
      case 'reset_peer': return ToxicRuleResetPeer
      }
    },
    loadServiceRuleset: function() {
      this.loading = true
      this.$store.dispatch('loadServiceRuleset', { serviceName: this.serviceName, ruleset: this.selectedRuleSet })
        .then(_ => {
          this.delay(() => {
            this.loading = false
            this.selectedRuleSet = '--'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .service-rules {
    .row {
      // margin: 0.75rem 0;
      &.odd {
        background-color: #ccc;
      }

      &.even {
        background-color: #eee;
      }
    }
  }

  .service-operations {
    margin-top: 2.2rem;

    .clear-items {
      display: flex;
      flex-direction: row;
      align-items: end;
      justify-content: center;
    }

    .available-rules, .available-types {
      button {
        margin-top: 1.2rem;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>

<style lang="scss">
  .service-rules .row {
    .col {
      padding: 0.75rem 0;
    }

    > div {
      display: grid;
      grid-template-columns: 1fr 2fr 4fr 6fr 1fr;
      border-left: 1px dotted #888;
    }

    &.header > div {
      border-left: none;
    }

    > div.stream div {
      padding: 0 0.75rem;
      display: flex;
      flex-direction: row;

      label {
        padding-left: 0.55rem;
      }
    }
    > div {
      display: flex;
      flex-direction: column;
    }
    div.attributes {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2.2rem;
      legend {
        font-weight: 900;
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
</style>
