<template>
  <div id="app">
    <b-container>
      <div
        v-if="loading"
        class="loading-spinner"
      >
        <LoadingSpinner>
          <div slot="title">
            Loading Toxic Proxy Configuration
          </div>
          <div slot="message">
            Please wait while the current Toxic Proxy Configuraton and available rules are loaded...
          </div>
        </LoadingSpinner>
      </div>
      <b-row
        class='main'
        v-if="!loading"
      >
        <b-col class="services">
          <services-table  />
        </b-col>
      </b-row>
      <b-row v-if="!loading" class="global-operations">
        <b-col class="load-default-rules">
          <div>
            <b-button
              variant="danger"
              @click="initializeDefaultRules"
            >
              Load Default Rules
            </b-button>
            <b-spinner
              v-if="initializingRules"
              variant="danger"
              style="width: 1.75rem; height: 1.75rem;"
            />
          </div>
        </b-col>

        <b-col class="clear-all-rules">
          <div>
            <b-button
              variant="danger"
              @click="clearAllRules"
            >
              Clear all rules
            </b-button>
            <b-spinner
              v-if="clearingRules"
              variant="danger"
              style="width: 1.75rem; height: 1.75rem;"
            />
          </div>
        </b-col>

      </b-row>

    </b-container>
  </div>
</template>

<script>
import ServicesTable from './components/ServicesTable.vue'
import CommonMixins from './components/mixins-common'

export default {
  name: 'app',
  components: {
    ServicesTable
  },
  mixins: [
    CommonMixins
  ],
  data: function() {
    return {
      loading: false,
      clearingRules: false,
      initializingRules: false
    }
  },
  mounted: function() {
    this.loading = true
    return Promise.all([
      this.$store.dispatch('loadProxyConfig'),
      this.$store.dispatch('loadAvailableRulesets')
    ])
      .then(_ => this.delay(() => { this.loading = false }, 250))
  },
  methods: {
    clearAllRules: function() {
      this.clearingRules = true
      return this.$store.dispatch('resetProxyConfig')
        .then(_ => {
          this.delay(() => {
            this.clearingRules = false
          })
        })
    },
    initializeDefaultRules: function() {
      this.initializingRules = true
      return this.$store.dispatch('resetProxyConfig')
        .then(_ => this.$store.dispatch('initDefaultRules'))
        .then(_ => Promise.all([
          this.$store.dispatch('loadProxyConfig'),
          this.$store.dispatch('loadAvailableRulesets')
        ]))
        .then(_ => {
          this.delay(() => {
            this.initializingRules = false
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;

  .services {
    border-bottom: 3px dotted black;
  }

  .global-operations {
    margin: 2.2rem 0;
  }

  .loading-spinner {
    padding: 2.2rem;
    border: 1px solid black;
  }
}
</style>
