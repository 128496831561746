<template>
  <b-container class="proxy-services-wrapper">
    <b-tabs content-class="mt-3">
      <b-tab
        v-for="(service, idx) in services"
        :key="idx"
        class="proxy-service"
        :title="service.name"
      >
        <b-row class="header">
          <b-col class="title">
            <div class="service-name">
              <label>Serivce:</label> {{ service.name }}
            </div>
            <div class="service-endopint">
              <label>Endpoint:</label>
              {{ service.service }}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <service-rules-table :service-name="service.name" />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import ServiceRulesTables from './ServiceRulesTable.vue'

export default {
  name: 'services-table',
  components: {
    'service-rules-table': ServiceRulesTables
  },
  data: function() {
    return {
      dirty: false,
      clearing: false,
      saving: false
    }
  },
  computed: {
    services: function() {
      return Object.entries(this.$store.getters.Services)
        .map(([service, data]) => ({
          name: service,
          service: data.listen,
          enabled: data.enabled,
          rules: data.toxics
        }))
    }
  }
}
</script>

<style lang="scss" scoped>

  .proxy-service {
    padding: 1.2rem 0;
    margin: 2.2rem 0;

    .header {
      margin: 0 0 2.2rem 0;
    }

    .title {
      font-size: 2.2rem;
      font-weight: 800;
    }
  }
</style>
