export default {
  computed: {
    isDirty: function() {
      return this.dirty || this.item.new
    }
  },
  methods: {
    delay: function(callback, timeout) {
      timeout = typeof timeout === 'number' ? timeout : 500
      setTimeout(function() {
        callback()
      }, timeout)
    },
    saveRule: function() {
      this.saving = true
      return this.$store.dispatch('saveAddedRule', {
        serviceName: this.serviceName,
        rule: {
          type: this.item.type,
          stream: this.value.stream,
          toxicity: this.value.toxicity / 100,
          attributes: this.value.attributes
        }
      })
        .then(_ => this.$store.dispatch('loadServiceRules', { serviceName: this.serviceName }))
        .then(_ => this.delay(() => {
          this.saving = false
          this.dirty = false
        }))
    },
    updateRule: function() {
      if (Object.prototype.hasOwnProperty.call(this.item, 'new') && this.item.new) {
        this.saving = true
        return this.$store.dispatch('saveAddedRule', {
          serviceName: this.serviceName,
          rule: {
            type: this.item.type,
            stream: this.value.stream,
            toxicity: this.value.toxicity / 100,
            attributes: this.value.attributes
          }
        })
          .then(_ => this.$store.dispatch('loadServiceRules', { serviceName: this.serviceName }))
          .then(_ => this.delay(() => {
            this.saving = false
            this.dirty = false
          }))
      }
      else if (this.item.stream !== this.value.stream) {
        this.saving = true
        this.$store.dispatch('deleteRule', {
          serviceName: this.serviceName,
          type: this.item.type,
          stream: this.item.stream
        })
          .then(_ => this.saveRule())
      }
      else {
        this.$store.dispatch('updateRule', {
          serviceName: this.serviceName,
          rule: {
            type: this.item.type,
            stream: this.value.stream,
            toxicity: this.value.toxicity / 100,
            attributes: this.value.attributes
          }
        })
          .then(_ => this.delay(() => {
            this.saving = false
            this.dirty = false
          }))
      }
    },
    deleteRule: function() {
      this.$store.dispatch('deleteRule', {
        serviceName: this.serviceName,
        type: this.item.type,
        stream: this.item.stream
      })
    }
  }
}
