import Vue from 'vue'
import async from 'async'
import omit from 'lodash/omit'

const state = () => ({
  services: {},
  rules: {}
})

const mutations = {
  SERVICES: function(state, payload) {
    state.services = payload
  },
  SERVICE_RULES: function(state, payload) {
    Vue.set(state.services[payload.serviceName], 'toxics', payload.rules)
  },
  RULES: function(state, payload) {
    state.rules = payload
  },
  ADD_NEW_RULE: function(state, payload) {
    state.services[payload.serviceName].toxics.push({ new: true, ...payload.rule })
  }
}

const getters = {
  ProxyEndpoint: function(state) {
    return process.env.VUE_APP_TOXIPROXY_ENDPOINT || window.location.origin
  },
  Services: function(state) {
    return state.services
  },
  ServiceRules: function(state) {
    return (serviceName) => {
      return state.services[serviceName].toxics
    }
  },
  availableServiceRules: function(state) {
    return (serviceName) => {
      return state.rules[serviceName]
    }
  },
  availableServiceRulesNames: function(state) {
    return (serviceName) => {
      return Object.entries(state.rules[serviceName])
        .reduce((acc, [name, data]) => ({ ...acc, [name]: { name: data.name, description: data.description } }), {})
    }
  }
}

const actions = {
  initDefaultRules: function({ state, dispatch }) {
    const rulesLoaders = []
    Object.entries(state.rules).forEach(([servicename, config]) => {
      if (Object.prototype.hasOwnProperty.call(config, 'default')) {
        state.rules[servicename].default.rules
          .forEach(rule => {
            rulesLoaders.push((cb) => dispatch('saveAddedRule', { serviceName: servicename, rule }).then(_ => cb()))
          })
      }
    })
    return async.parallelLimit(rulesLoaders, 5)
  },
  deleteRule: function({ dispatch, getters }, payload) {
    const ruleName = `${payload.type}_${payload.stream}`
    return fetch(`${getters.ProxyEndpoint}/proxies/${payload.serviceName}/toxics/${ruleName}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json; utf-8'
      }
    })
      .then(_ => dispatch('loadServiceRules', { serviceName: payload.serviceName }))
      .catch(err => {
        console.error(`Exception: ${err.message}`)
      })
  },
  updateRule: function({ dispatch, getters }, payload) {
    const ruleName = `${payload.rule.type}_${payload.rule.stream}`
    return fetch(`${getters.ProxyEndpoint}/proxies/${payload.serviceName}/toxics/${ruleName}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; utf-8'
      },
      body: JSON.stringify(payload.rule)
    })
      .then(_ => dispatch('loadServiceRules', { serviceName: payload.serviceName }))
      .catch(err => {
        console.error(`Exception: ${err.message}`)
      })
  },
  saveAddedRule: function({ getters }, payload) {
    return fetch(`${getters.ProxyEndpoint}/proxies/${payload.serviceName}/toxics`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; utf-8'
      },
      body: JSON.stringify(payload.rule)
    })
      .catch(err => {
        console.error(`Exception: ${err.message}`)
      })
  },
  loadServiceRuleset: function({ state, dispatch }, payload) {
    return dispatch('clearServiceRules', { serviceName: payload.serviceName })
      .then(_ => {
        return state.rules[payload.serviceName][payload.ruleset].rules
          .map(rule => (cb) => dispatch('saveAddedRule', { serviceName: payload.serviceName, rule }).then(_ => cb()))
      })
      .then(loaders => async.parallelLimit(loaders, 3))
      .then(_ => dispatch('loadServiceRules', { serviceName: payload.serviceName }))
  },
  clearServiceRules: function({ state, dispatch }, payload) {
    const processes = Object.values(state.services[payload.serviceName].toxics)
      .map(rule => {
        return (cb) => dispatch('deleteRule', { serviceName: payload.serviceName, ...omit(rule, ['name']) }).then(_ => cb())
      })

    return async.parallelLimit(processes, 3)
      .then(_ => dispatch('loadServiceRules', { serviceName: payload.serviceName }))
  },
  resetProxyConfig: function({ dispatch, getters }) {
    return fetch(`${getters.ProxyEndpoint}/reset`, { method: 'DELETE' })
      .then(_ => dispatch('loadProxyConfig'))
  },
  setProxyConfig: function({ dispatch, getters }, payload) {
    return fetch(`${getters.ProxyEndpoint}/config`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; utf-8'
      },
      body: JSON.stringify(payload)
    })
      .then(_ => dispatch('loadProxyConfig'))
      .catch(err => {
        console.error(`Exception: ${err.message}`)
      })
  },
  loadServiceRules: function({ commit, getters }, payload) {
    return fetch(`${getters.ProxyEndpoint}/proxies/${payload.serviceName}/toxics`, { method: 'GET' })
      .then(resp => {
        if (resp.ok) {
          return resp.json()
        }
        else {
          throw new Error('Error loading proxies services')
        }
      })
      .then(data => {
        commit('SERVICE_RULES', { serviceName: payload.serviceName, rules: data })
      })
      .catch(err => {
        console.error(`Exception: ${err.message}`)
      })
  },
  loadProxyConfig: function({ commit, getters }) {
    return fetch(`${getters.ProxyEndpoint}/config`, { method: 'GET' })
      .then(resp => {
        if (resp.ok) {
          return resp.json()
        }
        else {
          throw new Error('Error loading proxies services')
        }
      })
      .then(data => {
        commit('SERVICES', data)
      })
      .catch(err => {
        console.error(`Exception: ${err.message}`)
      })
  },
  loadAvailableRulesets: function({ commit, getters }) {
    return fetch(`${getters.ProxyEndpoint}/rules`, { method: 'GET' })
      .then(resp => {
        if (resp.ok) {
          return resp.json()
        }
        else {
          throw new Error('Error loading Available Services Rules')
        }
      })
      .then(data => {
        commit('RULES', data)
      })
  }
}
export default {
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
}
