<template>
  <b-row>
    <b-col class="name">
      <b-form-group>
        Latency
      </b-form-group>
    </b-col>
    <b-col class="stream">
      <b-form-group>
        <b-form-radio
          v-model="value.stream"
          value="upstream"
        >
          upstream
        </b-form-radio>
        <b-form-radio
          v-model="value.stream"
          value="downstream"
        >
          downstream
        </b-form-radio>
      </b-form-group>
    </b-col>
    <b-col class="toxicity">
      <b-form-group>
        <b-form-slider
          v-model="value.toxicity"
          :min="0"
          :max="100"
          :step="5"
        />
        <div>
          {{ (value.toxicity / 100).toFixed(2) }}
        </div>
      </b-form-group>
    </b-col>
    <b-col class="attributes">
      <div>
        <b-form-group
          label="Latency"
          class="latency"
        >
          <b-form-slider
            v-model="value.attributes.latency"
            :min="0"
            :max="5000"
            :step="250"
          />
          <div>
            {{ value.attributes.latency }} ms
          </div>
        </b-form-group>
      </div>
      <div>
        <b-form-group
          label="Jitter"
          class="jitter"
        >
          <b-form-slider
            v-model="value.attributes.jitter"
            :min="0"
            :max="5000"
            :step="250"
          />
          <div>
            {{ value.attributes.jitter }} ms
          </div>
        </b-form-group>
      </div>
    </b-col>
    <b-col class="buttons">
      <div>
        <b-button
          :class="{ 'enabled': isDirty }"
          variant="success"
          :disabled="!isDirty"
          @click="updateRule"
        >
          Save
        </b-button>
      <b-spinner
        v-if="saving"
        variant="danger"
        style="width: 1.75rem; height: 1.75rem;"
      />
      </div>
      <div>
        <b-button
          @click="deleteRule"
          variant="danger"
        >
          Delete
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import CommonMixins from '../mixins-common'

export default {
  name: 'toxic-rule-latency',
  mixins: [
    CommonMixins
  ],
  props: {
    serviceName: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      dirty: false,
      saving: false,
      value: {
        stream: this.item.stream,
        toxicity: this.item.toxicity * 100,
        attributes: {
          latency: this.item.attributes.latency,
          jitter: this.item.attributes.jitter
        }
      }
    }
  },
  watch: {
    value: {
      handler: function() {
        this.dirty = true
      },
      deep: true
    }
  }
}
</script>

<style>

</style>
